import React from 'react'
import Link from 'gatsby-link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Paginator from '../components/Paginator'

const Template = ({ pageContext }) => {
  const { group, index, pageCount, pathPrefix } = pageContext

  return (
    <Layout>
      <SEO title="Blog" />
      <Paginator
        index={index}
        pageCount={pageCount}
        relativeUrl={`/${pathPrefix}`}
      />
      {group.map(({ node }) => (
        <div className="normal-homepage-item" key={node.id}>
          <Link className="title" to={node.frontmatter.path}>
            <h3>{node.frontmatter.title}</h3>
          </Link>
          <h6 className="information">
            Posted by {node.frontmatter.author} on {node.frontmatter.date}
          </h6>
          <Link className="readMore" to={node.frontmatter.path}>
            Read More
          </Link>
          <br />
        </div>
      ))}
      <Paginator
        index={index}
        pageCount={pageCount}
        relativeUrl={`/${pathPrefix}`}
      />
    </Layout>
  )
}

export default Template
